.wiki-background {
    background-image: url(../assets/Bg15.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
  }


  .wiki-background::before,
.wiki-background::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 50%;
    pointer-events: none;
}

.wiki-background::before {
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.wiki-background::after {
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.glow {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 30px rgba(255, 255, 255, 1);
}