.mainer-background {
    position: relative;
    background-image: url(../assets/compass.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: wiggle 1s ease-in-out infinite;
}

.mainer-background::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(5, 5, 5, 0.139);
    pointer-events: none;
    z-index: 1;
    
}
