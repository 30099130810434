@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
 
}

html {
  scroll-behavior: smooth;
}
